import { NAMESPACES } from 'constants/locales'
import { PAGE_PROPS, PAGE_DEFAULT_PROPS } from 'constants/prop-types'
import { PROPERTY } from 'constants/tracking'

import {
  X_DEFAULT_LOCALE,
  LANGUAGE_CODES,
} from '@typeform/ginger/dist/lib/i18n'
import React, { useEffect } from 'react'
import { getCommonServerSideProps } from 'utils/server'
import { routes } from 'utils/routes'
import { toCanonicalURL } from 'utils/url-analyzers'
import useTranslation from 'next-translate/useTranslation'
import DocumentHead from 'components/head'
import OktaSignupPage from 'components/okta-signup-page'
import { OktaWidgetStyles } from 'components/okta-widget/okta-widget.styles'
import { logError } from 'server/utils/logger'

const Signup = ({
  metaTags,
  availableLocales,
  currentUrl,
  locale,
  oktaConfig,
  device,
  baseUrl,
}) => {
  const { t } = useTranslation(NAMESPACES.AUTH)

  useEffect(() => {
    // Okta re-renders the form when signup flow is completed. It also updates the URL
    // This creates a navigation bug where user needs to click back button twice to go to
    // previous page.
    // With this function above we make sure user just needs to click once to go back.
    // More info here: https://jira.typeform.tf/browse/WEB-4297
    window.onpopstate = function () {
      window.history.back()
      window.location.reload()
    }

    return () => {
      window.onpopstate = undefined
    }
  }, [])

  return (
    <>
      <DocumentHead
        {...metaTags}
        title={t('auth.signup.meta-tag.title')}
        description={t('auth.signup.meta-tag.description')}
        currentUrl={currentUrl}
        availableLocales={availableLocales}
        ogLocale={locale}
        hrefLangLinks={[
          {
            hreflang: LANGUAGE_CODES.english,
            href: routes(LANGUAGE_CODES.english, baseUrl).signup,
          },
          {
            hreflang: LANGUAGE_CODES.spanish,
            href: routes(LANGUAGE_CODES.spanish, baseUrl).signup,
          },
          {
            hreflang: X_DEFAULT_LOCALE,
            href: routes(X_DEFAULT_LOCALE, baseUrl).signup,
          },
        ]}
      />
      <div data-testid='okta-signup-page'>
        <OktaWidgetStyles />
        <OktaSignupPage
          locale={locale}
          oktaConfig={oktaConfig}
          device={device}
        />
      </div>
    </>
  )
}

export const getServerSideProps = async ctx => {
  try {
    const commonServerSideProps = await getCommonServerSideProps(ctx)

    const baseUrl = toCanonicalURL()

    return {
      props: {
        ...commonServerSideProps,
        baseUrl,
        appConfig: {
          headerProps: {
            showHeader: false,
          },
          footerProps: {
            showFooter: false,
          },
          showBanners: false,
        },
        pageContent: {
          trackingConfig: {
            viewPageSectionProps: {
              page: 'signup',
              typeform_property: PROPERTY.ADMIN_ACCESS,
            },
          },
        },
      },
    }
  } catch (error) {
    logError({
      message: 'Error in signup getServerSideProps',
      error,
      additionalInfo: { section: 'signup' },
    })
    return {
      notFound: true,
    }
  }
}

Signup.displayName = 'Signup'

export default Signup

Signup.propTypes = {
  ...PAGE_PROPS,
}
Signup.defaultProps = {
  ...PAGE_DEFAULT_PROPS,
}
